import requests from "@/utils/axios";
import { AxiosRequestConfig } from "axios";

export default {
    // 模块URL
    modulesUrl: "/aftersale/report/work/efficiency/userEfficiency",
    // 列表
    list(query: any) {
        return requests.post(this.modulesUrl + "/list", query);
    },
    // 导出Url
    exportUrl() {
        return this.modulesUrl + "/export";
    },
    // 导出
    export(query: any) {
        return requests.post(this.modulesUrl + "/export", query, <AxiosRequestConfig>{ responseType: "blob" });
    },
};
