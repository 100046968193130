import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import DropdownButton from "@/modules/dropdown-button/dropdown-button.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import StatusTableItem from "@/entity/aftersale/ticket/StatusTableItem";
import API from "@/api/aftersale/report/work/efficiency/userEfficiency";
import axios, { AxiosRequestConfig } from "axios";
import { httpTimeout, HttpUrl } from "@/utils/axios";
import { decrypt } from "@/utils/cryptojs";
import store from "@/store";

@Options({
    name: "app-aftersale-report-work-efficiency-user",
    components: {
        "app-select-level": SelectLevel,
        "app-dropdown-button": DropdownButton,
        "app-draggable-listview": DraggableListview,
    },
})
export default class UserEfficiency extends BaseTableMixins<StatusTableItem> implements Partial<BaseTableInterface<StatusTableItem>> {
    private exportDropdown = false;
    private detailModalTitle = "";
    private detailItem: any = {};

    public created(): void {
        this.setInterface(this);
        // this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new StatusTableItem();
        this.editModalItem = new StatusTableItem();
    }
    public activated(): void {
        const dateTime = this.dateTimeShortcuts[0].value();
        this.tableRequestItem.dateTime = [this.$tools.formatTime(dateTime[0]), this.$tools.formatTime(dateTime[1])];
        this.getList(1);
    }
    //后端API
    public getApi(): any {
        return API;
    }

    //查看明细
    public detailModalOpen(item: any): void {
        this.detailModalTitle = item.assigneeUser.title + "审批的工单";
        this.detailItem = item.taskList;
        (this.$refs.detailModal as any).openModal();
    }

    //导出
    public tableExportFunction(type: any) {
        this.isLoading = true;
        let title: any = [];
        if ("report" == type) {
            title = this.getTableTitle;
        } else if ("detail" == type) {
            title = {
                id: "ID",
                taskName: "任务名称",
                processStatusName: "流程状态",
                "assigneeUser.title": "处理人",
                workCode: "工单编号",
                workSubject: "工单主题",
                fromOrgTypeIdName: "组织类型",
                fromOrgCode: "组织代码",
                fromOrgName: "所属组织",
                projectIdName: "所属设备",
                businessIdName: "故障类型",
                influenceIdName: "影响范围",
                workStar: "工单星级",
                taskCreatedTime: "开始时间",
                endTime: "结束时间",
                nodeDuration: "节点用时",
                workDescribeLaunch: "故障简述",
                comments: "处理意见",
            };
        } else {
            this.toast.error("导出类型错误");
            return;
        }
        axios
            .create({ baseURL: HttpUrl, timeout: httpTimeout, headers: { "Content-Type": "application/json", authorization: decrypt(this.$store.getters["auth/token"]) } })
            .post(this.API.exportUrl(), Object.assign({}, this.tableRequestItem, { export: true, title, type }), <AxiosRequestConfig>{ responseType: "blob" })
            .then((response) => {
                //console.log(response);
                response.data.text().then((text: any) => {
                    //console.log(text);
                    //接受token
                    const token = response.headers["authorization"];
                    if (token) store.dispatch("auth/setToken", token);
                    //解析是否是错误提示
                    const res = this.$tools.parseJSON(text);
                    if (res) {
                        this.toast.error(res.msg);
                        return;
                    }
                    //获取文件名称
                    let filename = "运维平台-工单人效报表-数据导出-" + new Date().getTime() + ".xlsx";
                    if ("content-disposition" in response.headers) {
                        const content_disposition = response.headers["content-disposition"];
                        filename = content_disposition.split("filename=")[1];
                        filename = filename.split(";")[0];
                        filename = filename.replaceAll('"', "");
                        filename = decodeURI(filename);
                    }
                    //下载导出文件
                    const blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
                    if (window.navigator.msSaveOrOpenBlob) {
                        // 兼容IE10及以上版本
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;
                        link.click();
                        window.URL.revokeObjectURL(link.href);
                    }
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}
